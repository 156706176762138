import { BOOKING_STATUS, FRIENDLY_ROOM_CATEGORIES } from "../../utils/constant";
import { VideoUploadResult } from "../types";

export type CategoryType =
  typeof FRIENDLY_ROOM_CATEGORIES[keyof typeof FRIENDLY_ROOM_CATEGORIES];

export type ServiceType = "event" | "world";

export type RoomCategoryType =
  | "tour"
  | "explorePlace"
  | "explore"
  | "therapy"
  | "karaoke"
  | "music"
  | "languageLearning"
  | "lab"
  | "news"
  | "timeCapsule"
  | "seminar"
  | "talk"
  | "learningEducation"
  | "animalsPet"
  | "festival"
  | "gourmet"
  | "sports"
  | "gaussianVr";

export type PaymentType = "onetime" | "subscription";

export type RoomBookingType =
  typeof BOOKING_STATUS[keyof typeof BOOKING_STATUS];

export interface RoomInterface {
  id: string;
  name: string;
  roomId: string;
  key: string;
  url: string;
  languages: string;
}

export interface BookingInterface {
  id: number;
  type: string | null;
  Itinerary: {
    id: number;
    timeStart: Date;
    timeEnd: Date;
    Room: Service;
  };
}

export interface FavouriteVideo {
  id: number;
  name: string;
  size: number;
  thumbnailUrl: string;
  roomId: string;
  resolution: string;
  youtubeUrl: string;
  streamUrl: string;
  streamCloudFrontUrl: string;
  s3Url: string;
  cloudFrontUrl: string;
  pcUrl: string;
  pcCloudFrontUrl: string;
  p480Url: string;
  p480CloudFrontUrl: string;
  rawStorage: string;
  pcStreamUrl: string;
  defaultRoomId: string;
  difficulty: string | null;
  Rooms?: QuotedWorldRoomInterface[];
}

export interface QuotedWorldRoomInterface {
  id: number;
  title: string;
  description: string;
  type: string;
}

export interface RoomIdVideo {
  id: number;
  name: string;
  defaultRoomId: string;
}

export interface RegisterTourVideo {
  active: boolean;
  backupUrl: string;
  defaultRoomId: string;
  description: string;
  id: number;
  isPreview: boolean;
  name: string;
  ownedByTDX: boolean;
  p480Url: string | null;
  pcStreamUrl: string | null;
  pcUrl: string | null;
  publish: boolean;
  rawStorage: string | null;
  resolution: string;
  s3Url: string | null;
  streamUrl: string | null;
  userId: string | null;
  difficulty: string | null;
}

export interface VUMSVideo {
  active: boolean;
  backupUrl: string;
  defaultRoomId: string;
  description: string;
  id: number;
  isPreview: boolean;
  name: string;
  ownedByTDX: boolean;
  p480Url: string | null;
  pcStreamUrl: string | null;
  pcUrl: string | null;
  publish: boolean;
  rawStorage: string | null;
  resolution: string;
  s3Url: string | null;
  streamUrl: string | null;
  userId: string | null;
  difficulty: string | null;
  size: number;
  Rooms?: QuotedWorldRoomInterface[];
}

export interface LibraryVideo {
  defaultRoomId: string;
  id: number;
  name: string;
  vrQuality1Url: string | null;
  previewUrl: string | null;
  s3Url: string | null;
  userId: string | null;
  difficulty: string | null;
}

export interface SelfExploreVideo {
  id: number;
  title: string;
  titleJp: string | null;
  location: string;
  photos: any[];
  videoId: number;
  difficulty: string | null;
  refUrl: string;

  // Workaround (Better solution to be looked at in long run)
  // `combinedId` is a string, but needs to be treated as a number also as
  // a workaround when receiving data from Ant Design Table to custom code
  // For example, '12-14' as string is 12 as number
  // where 12 is id (videoId) and 14 is explorePlaceId
  // To avoid key duplication we need to use '12-14' as the key
  // whereas, id (videoId) which is 12 will be finally received in the
  // handshake phase as a number type
  combinedId: string | number;
}

export interface StreamingTestVideo {
  active: boolean;
  backupUrl: string;
  defaultRoomId: string;
  description: string;
  id: number;
  isPreview: boolean;
  name: string;
  ownedByTDX: boolean;
  p480Url: string | null;
  pcStreamUrl: string | null;
  pcUrl: string | null;
  publish: boolean;
  rawStorage: string | null;
  resolution: string;
  s3Url: string | null;
  streamUrl: string | null;
  userId: string | null;
  isTestingVideoJP: boolean | null;
}
export interface TrialVideo {
  id?: number;
  video?: string;
}

export interface PartyRoomInterface {
  Video: any;
  id: string;
  videoId: string;
  key: string;
  url: string;
  userId: number;
}

export interface TherapyRoomInterface {
  Video: any;
  id: string;
  videoId: string;
  title: string;
  key: string;
  url: string;
  photos: string[];
  userId: number;
}

export interface IPartyLog {
  id: string;
  roomId: string;
  plan: string;
  joinedTime: string;
  leavingTime?: string;
  stayingTime?: string;
  User?: {
    id: string;
    name: string;
  };
  updatedAt: string;
}

export interface AuthorInterface {
  id: number;
  hostName: string;
  hostPhoto?: string;
  webpHostPhoto?: string;
  pngHostPhoto?: string;
}

export interface EarliestFutureItineraryInterface {
  id: number;
  roomId: number;
  timeStart: Date | string;
  timeEnd: Date | string;
  ItineraryPrices: any;
}

export interface Service {
  id: number;
  guideId?: number;
  pngImageUrl: string;
  webpImageUrl: string;
  category: CategoryType;
  categorySlug?: RoomCategoryType;
  type: ServiceType;
  paymentType?: PaymentType;
  location?: string;
  author: AuthorInterface;
  title: string;
  titleJp?: string | null;
  titleZhcn?: string | null;
  titleZhtw?: string | null;
  titleVi?: string | null;
  titleHi?: string | null;
  description?: string | null;
  descriptionJp?: string | null;
  descriptionZhcn?: string | null;
  descriptionZhtw?: string | null;
  descriptionVi?: string | null;
  descriptionHi?: string | null;
  averagedEvaluations: number;
  reviewsCount?: number;
  price?: number;
  isFree: boolean;
  isLiveStream: boolean;
  isSkeleton?: boolean;
  Itinerary?: {
    timeStart: string;
    timeEnd: string;
    earliestFutureItinerary: EarliestFutureItineraryInterface;
  };
  createdAt?: string;
  totalParticipants?: number;
  video?: {
    id: number;
    name: string;
    status: VideoUploadResult;
    defaultRoomId?: string;
    s3Url?: string;
    pcUrl?: string;
    previewUrl?: string;
  };
  RoomPrices?: {
    currency: string;
    amount: string;
  }[];
  disabledPostReview?: boolean;
  disabledPostReviewReason?: "own-room" | "already-posted" | null;
  pageView: number;
  isRecommended: boolean;
}

export interface ServicesActionInterface {
  type?: string | null;
  category?: string | null;
  keyword?: string | null;
  showBy?: string | null;
  startDate?: string;
  endDate?: string;
  timezoneOffset?: number;
  paymentUSD?: string | null;
  isFree?: boolean;
  priceRange?: string | null;
  spotCodes?: string | null;
  countryCodes?: string | null;
  mainCategoryIds?: string | null;
  subCategoryIds?: string | null;
  page?: number | null;
  userId?: number;
  hasBestScenes?: boolean;
  serviceType?: string;
  rmCategory?: string;
}

export interface HistoryDetail {
  id: number;
  roomId: number;
  userId: number;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | string | null;
  Room: Service;
}

export interface HistoriesActionInterface {
  type?: string | null;
  category?: string | null;
  page?: number | null;
}

export interface BookingsActionInterface {
  type?: string | null;
  category?: string | null;
  page?: number | null;
  showBy?: string | null;
  activeOnly?: boolean;
}

export type WrBestSceneType = "wrBestScene" | "wrBestSceneKids";

export interface WrBestSceneInterface {
  id: number;
  Video: {
    id: number;
    defaultRoomId: string;
    pcUrl: string;
  };
  author: AuthorInterface;
}

export interface TripTutorialInterface {
  staticVideoLink: string;
  author: AuthorInterface;
}

export interface StatisticItemInterface {
  category: RoomCategoryType;
  count: number;
}

export enum RoomActions {
  GET_ROOMS = "room/GET_ROOMS",
  GET_WR_BEST_SCENE = "room/GET_WR_BEST_SCENE",
  GET_WR_BEST_SCENE_KIDS = "room/GET_WR_BEST_SCENE_KIDS",
  GET_SELECTED_VIDEO = "room/GET_SELECTED_VIDEO",
  GET_FAVOURITE_VIDEOS = "room/GET_FAVOURITE_VIDEOS",
  GET_FREE_LIBRARY_VIDEOS = "room/GET_FREE_LIBRARY_VIDEOS",
  GET_PERSONAL_LIBRARY_VIDEOS = "room/GET_PERSONAL_LIBRARY_VIDEOS",
  GET_ROOM_ID_VIDEO = "room/GET_ROOM_ID_VIDEO",
  GET_REGISTER_TOUR_VIDEO = "room/GET_REGISTER_TOUR_VIDEO",
  SET_ERRORS = "room/SET_ERRORS",
  CLEAR_ERRORS = "room/CLEAR_ERRORS",
  SET_IS_SUBMITTING = "room/SET_IS_SUBMITTING",
  CREATE_ROOM = "room/CREATE_ROOM",
  UPDATE_ROOM = "room/UPDATE_ROOM",
  DELETE_ROOM = "room/DELETE_ROOM",
  UPDATE_VIDEO_HOST = "room/UPDATE_VIDEO_HOST",
  GET_VUMS_VIDEOS = "room/GET_VUMS_VIDEOS",
  GET_SELF_EXPLORE_VIDEOS = "room/GET_SELF_EXPLORE_VIDEOS",
  GET_PARTY_ROOMS = "room/GET_PARTY_ROOMS",
  GET_THERAPY_ROOMS = "room/GET_THERAPY_ROOMS",
  GET_STREAMING_TEST_VIDEO_PUBLIC = "room/GET_STREAMING_TEST_VIDEO_PUBLIC",
  GET_STREAMING_TEST_VIDEO = "room/GET_STREAMING_TEST_VIDEO",
  SET_STREAMING_TEST_VIDEO = "room/SET_STREAMING_TEST_VIDEO",
  SET_TRIAL_VIDEO = "room/SET_TRIAL_VIDEO",
  SET_PARTY_LOG = "room/SET_PARTY_LOG",
  DELETE_SERVICE = "room/DELEE_SERVICE",
  GET_SERVICES = "room/GET_SERVICES",
  GET_SERVICES_SUCCESS = "room/GET_SERVICES_SUCCESS",
  GET_SERVICES_FAIL = "room/GET_SERVICES_FAIL",
  GET_EVENT_NEAR_DATE_SERVICES_SUCCESS = "room/GET_EVENT_NEAR_DATE_SERVICES_SUCCESS",
  GET_POPULAR_WORLD_SERVICES_SUCCESS = "room/GET_POPULAR_WORLD_SERVICES_SUCCESS",
  GET_RECOMMEND_WORLD_SERVICES_SUCCESS = "room/GET_RECOMMEND_WORLD_SERVICES_SUCCESS",
  GET_RECENT_POST_EVENT_SERVICES_SUCCESS = "room/GET_RECENT_POST_EVENT_SERVICES_SUCCESS",
  GET_RECENT_POST_WORLD_SERVICES_SUCCESS = "room/GET_RECENT_POST_WORLD_SERVICES_SUCCESS",
  GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_REQUEST = "room/GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_REQUEST",
  GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_SUCCESS = "room/GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_SUCCESS",
  GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_FAILURE = "room/GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_FAILURE",
  GET_HISTORIES_REQUEST = "room/GET_HISTORIES_REQUEST",
  GET_HISTORIES_SUCCESS = "room/GET_HISTORIES_SUCCESS",
  GET_HISTORIES_FAILURE = "room/GET_HISTORIES_FAILURE",
  GET_BOOKINGS_REQUEST = "room/GET_BOOKINGS_REQUEST",
  GET_BOOKINGS_SUCCESS = "room/GET_BOOKINGS_SUCCESS",
  GET_BOOKINGS_FAILURE = "room/GET_BOOKINGS_FAILURE",
  GET_BOOKINGS_VIEW_ALL_REQUEST = "room/GET_BOOKINGS_VIEW_ALL_REQUEST",
  GET_BOOKINGS_VIEW_ALL_SUCCESS = "room/GET_BOOKINGS_VIEW_ALL_SUCCESS",
  GET_BOOKINGS_VIEW_ALL_FAILURE = "room/GET_BOOKINGS_VIEW_ALL_FAILURE",
  GET_STATISTICS_REQUEST = "room/GET_STATISTICS_REQUEST",
  GET_STATISTICS_SUCCESS = "room/GET_STATISTICS_SUCCESS",
  GET_STATISTICS_FAILURE = "room/GET_STATISTICS_FAILURE",
}

export interface PurchaseHistoryItemByUser {
  id: number;
  userId: number;
  status: RoomBookingType;
  type: string | null;
  createdAt: Date | string;
  Itinerary: {
    id: number;
    timeStart: string;
    timeEnd: string;
    status: string;
    Room: {
      photos: string[];
      id: number;
      title: string;
      titleJp: string | null;
      titleZhcn: string | null;
      titleZhtw: string | null;
      titleVi: string | null;
      titleHi: string | null;
      type: ServiceType;
      paymentType: string;
      guideId: number;
      category: RoomCategoryType;
      isLiveStream: boolean;
      Video?: {
        id: number;
        defaultRoomId: string;
        s3Url: string;
        pcUrl: string;
      };
    };
  };
}
