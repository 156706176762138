import { Dayjs } from "dayjs";
import { Permission } from "../tour/types";

export enum TripActions {
  GET_TRIP_SELECTED_VIDEO_REQUEST = "GET_TRIP_SELECTED_VIDEO_REQUEST",
  GET_TRIP_SELECTED_VIDEO_SUCCESS = "GET_TRIP_SELECTED_VIDEO_SUCCESS",
  GET_TRIP_SELECTED_VIDEO_FAILURE = "GET_TRIP_SELECTED_VIDEO_FAILURE",
  GET_TRIP_TOUR_STEP_2_DETAIL_REQUEST = "GET_TRIP_TOUR_STEP_2_DETAIL_REQUEST",
  GET_TRIP_TOUR_STEP_2_DETAIL_SUCCESS = "GET_TRIP_TOUR_STEP_2_DETAIL_SUCCESS",
  GET_TRIP_TOUR_STEP_2_DETAIL_FAILURE = "GET_TRIP_TOUR_STEP_2_DETAIL_FAILURE",
  MUTATE_TRIP_TOUR_STEP_2_REQUEST = "MUTATE_TRIP_TOUR_STEP_2_REQUEST",
  MUTATE_TRIP_TOUR_STEP_2_SUCCESS = "MUTATE_TRIP_TOUR_STEP_2_SUCCESS",
  MUTATE_TRIP_TOUR_STEP_2_FAILURE = "MUTATE_TRIP_TOUR_STEP_2_FAILURE",
  GET_TRIP_TOUR_STEP_3_DETAIL_REQUEST = "GET_TRIP_TOUR_STEP_3_DETAIL_REQUEST",
  GET_TRIP_TOUR_STEP_3_DETAIL_SUCCESS = "GET_TRIP_TOUR_STEP_3_DETAIL_SUCCESS",
  GET_TRIP_TOUR_STEP_3_DETAIL_FAILURE = "GET_TRIP_TOUR_STEP_3_DETAIL_FAILURE",
  MUTATE_TRIP_TOUR_STEP_3_REQUEST = "MUTATE_TRIP_TOUR_STEP_3_REQUEST",
  MUTATE_TRIP_TOUR_STEP_3_SUCCESS = "MUTATE_TRIP_TOUR_STEP_3_SUCCESS",
  MUTATE_TRIP_TOUR_STEP_3_FAILURE = "MUTATE_TRIP_TOUR_STEP_3_FAILURE",
  GET_TRIP_TOUR_STEP_4_DETAIL_REQUEST = "GET_TRIP_TOUR_STEP_4_DETAIL_REQUEST",
  GET_TRIP_TOUR_STEP_4_DETAIL_SUCCESS = "GET_TRIP_TOUR_STEP_4_DETAIL_SUCCESS",
  GET_TRIP_TOUR_STEP_4_DETAIL_FAILURE = "GET_TRIP_TOUR_STEP_4_DETAIL_FAILURE",
  MUTATE_TRIP_TOUR_STEP_4_REQUEST = "MUTATE_TRIP_TOUR_STEP_4_REQUEST",
  MUTATE_TRIP_TOUR_STEP_4_SUCCESS = "MUTATE_TRIP_TOUR_STEP_4_SUCCESS",
  MUTATE_TRIP_TOUR_STEP_4_FAILURE = "MUTATE_TRIP_TOUR_STEP_4_FAILURE",
  GET_TRIP_TOUR_STEP_5_DETAIL_REQUEST = "GET_TRIP_TOUR_STEP_5_DETAIL_REQUEST",
  GET_TRIP_TOUR_STEP_5_DETAIL_SUCCESS = "GET_TRIP_TOUR_STEP_5_DETAIL_SUCCESS",
  GET_TRIP_TOUR_STEP_5_DETAIL_FAILURE = "GET_TRIP_TOUR_STEP_5_DETAIL_FAILURE",
  MUTATE_TRIP_TOUR_STEP_5_REQUEST = "MUTATE_TRIP_TOUR_STEP_5_REQUEST",
  MUTATE_TRIP_TOUR_STEP_5_SUCCESS = "MUTATE_TRIP_TOUR_STEP_5_SUCCESS",
  MUTATE_TRIP_TOUR_STEP_5_FAILURE = "MUTATE_TRIP_TOUR_STEP_5_FAILURE",
  GET_TRIP_TOUR_STEP_6_DETAIL_REQUEST = "GET_TRIP_TOUR_STEP_6_DETAIL_REQUEST",
  GET_TRIP_TOUR_STEP_6_DETAIL_SUCCESS = "GET_TRIP_TOUR_STEP_6_DETAIL_SUCCESS",
  GET_TRIP_TOUR_STEP_6_DETAIL_FAILURE = "GET_TRIP_TOUR_STEP_6_DETAIL_FAILURE",
  MUTATE_TRIP_TOUR_STEP_6_REQUEST = "MUTATE_TRIP_TOUR_STEP_6_REQUEST",
  MUTATE_TRIP_TOUR_STEP_6_SUCCESS = "MUTATE_TRIP_TOUR_STEP_6_SUCCESS",
  MUTATE_TRIP_TOUR_STEP_6_FAILURE = "MUTATE_TRIP_TOUR_STEP_6_FAILURE",
  DELETE_TRIP_TOUR_ITINERARY_REQUEST = "DELETE_TRIP_TOUR_ITINERARY_REQUEST",
  DELETE_TRIP_TOUR_ITINERARY_SUCCESS = "DELETE_TRIP_TOUR_ITINERARY_SUCCESS",
  DELETE_TRIP_TOUR_ITINERARY_FAILURE = "DELETE_TRIP_TOUR_ITINERARY_FAILURE",
}

export interface TripSelectedVideo {
  id: number;
  name: string;
  size: number;
  defaultRoomId: string;
  difficulty: string | null;
}

export interface Schedule {
  index?: number;
  isNewData?: boolean;
  id?: number;
  timeStart: Dayjs;
  timeEnd: Dayjs;
}

export interface ScheduleDateTime {
  index?: number;
  isNewData?: boolean;
  id?: number;
  date: Dayjs | string;
  startTime: Dayjs | string;
  endTime: Dayjs | string;
}

export interface TripTourStep2FormInput {
  id?: number;
  location: string;
  countryId: string;
  primaryLanguage: string;
  title: string;
  description: string;
  photos: any[];
  videoId?: number;
  hostName: string;
  hostPhoto: string;
  hostStory: string;
  hostLocation: string;
  hostLanguage: string;
  hostOtherLanguages: string[];
  isCompleted: boolean;
  type?: string;
  category?: string;
  refRoomId?: number;
  labUrl?: string;
  isLiveStream?: boolean;
}

export interface TripTourStep3FormInput {
  id: number;
  primaryLanguage: string;
  title: string;
  description: string;
  hostStory: string;
  titleJp: string;
  descriptionJp: string;
  hostStoryJp: string;
  titleZhcn: string;
  descriptionZhcn: string;
  hostStoryZhcn: string;
  titleZhtw: string;
  descriptionZhtw: string;
  hostStoryZhtw: string;
  titleVi: string;
  descriptionVi: string;
  hostStoryVi: string;
  titleHi: string;
  descriptionHi: string;
  hostStoryHi: string;
}

export interface TripTourStep4FormInput {
  id: number;
  isFree: boolean;
  language: string[];
  otherLanguages: string[];
  mainCategories: (number | string)[];
  subCategories: (number | string)[];
  freeDescriptions: {
    [key: string]: string;
  };

  // Legacy
  minimum?: number;
  maximum?: number;
  paymentUSD?: number;

  // New
  prices?: {
    name: string;
    amount: number;
    currency: string;
    minimum: number;
    maximum: number;
  }[];
  permission?: string;
  friendIds?: number[];
}

export interface TripTourStep5FormInput {
  id: number;
  itinerary: Schedule[];
}

export interface TripTourStep6FormInput {
  id: number;
  permission?: Permission;
  isCompleted: boolean;
}

export interface TourMutationResponseData {
  tour: {
    id: number;
    isCompleted: boolean;
  };
}

export interface TourItineraryDeleteResponseData {
  tourId: number;
  itineraryId: number;
}

export interface CategoryTag {
  id: string;
  name: string;
  nameJp: string;
  nameZhcn: string;
  nameZhtw: string;
  nameVi: string;
  nameHi: string;
}
