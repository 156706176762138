import { SearchTourBookingInterface } from "../tour/types";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { countriesGeoInfo } from "../../utils/countryGeoInfo";
import { RoomCategoryType, ServiceType } from "../room/types";

export enum SelfhostActions {
  CREATE_TOUR_REQUEST = "selfhost/CREATE_TOUR_REQUEST",
  CREATE_TOUR_SUCCESS = "selfhost/CREATE_TOUR_SUCCESS",
  GET_TOUR_LIST_REQUEST = "selfhost/GET_TOUR_LIST_REQUEST",
  GET_TOUR_LIST_SUCCESS = "selfhost/GET_TOUR_LIST_SUCCESS",
  GET_TOUR_LIST_SEARCH_SUCCESS = "selfhost/GET_TOUR_LIST_SEARCH_SUCCESS",
  UPDATE_SINGLE_TOUR_REQUEST = "selfhost/UPDATE_SINGLE_TOUR_REQUEST",
  UPDATE_SINGLE_TOUR_SUCCESS = "selfhost/UPDATE_SINGLE_TOUR_SUCCESS",
  DELETE_SINGLE_TOUR_SUCCESS = "selfhost/DELETE_SINGLE_TOUR_SUCCESS",
  GET_TOUR_DETAIL_REQUEST = "selfhost/GET_TOUR_DETAIL_REQUEST",
  GET_TOUR_DETAIL_SUCCESS = "selfhost/GET_TOUR_DETAIL_SUCCESS",
  UPDATE_TOUR_DETAIL_REQUEST = "selfhost/UPDATE_TOUR_DETAIL_REQUEST",
  UPDATE_TOUR_DETAIL_SUCCESS = "selfhost/UPDATE_TOUR_DETAIL_SUCCESS",
  SET_ERRORS = "selfhost/SET_ERRORS",
  GET_TOUR_HISTORIES_REQUEST = "selfhost/GET_TOUR_HISTORIES_REQUEST",
  GET_TOUR_HISTORIES_SUCCESS = "selfhost/GET_TOUR_HISTORIES_SUCCESS",
  SEND_TOUR_REVIEW_REQUEST = "selfhost/SEND_TOUR_REVIEW_REQUEST",
  SEND_TOUR_REVIEW_SUCCESS = "selfhost/SEND_TOUR_REVIEW_SUCCESS",
  DELETE_SELFHOST_REVIEW_REQUEST = "selfhost/DELETE_SELFHOST_REVIEW_REQUEST",
  DELETE_SELFHOST_REVIEW_SUCCESS = "selfhost/DELETE_SELFHOST_REVIEW_SUCCESS",
  DELETE_SELFHOST_REVIEW_ERROR = "selfhost/DELETE_SELFHOST_REVIEW_ERROR",
  UPDATE_TOUR_STATUS_REQUEST = "selfhost/UPDATE_TOUR_STATUS_REQUEST",
  UPDATE_TOUR_STATUS_REQUEST_SUCCESS = "selfhost/UPDATE_TOUR_STATUS_REQUEST_SUCCESS",
  UPDATE_TOUR_STATUS_REQUEST_ERROR = "selfhost/UPDATE_TOUR_STATUS_REQUEST_ERROR",
  GET_WR_EVENTS_FOR_USER = "selfhost/GET_WR_EVENTS_FOR_USER",
  GET_WR_EVENTS_FOR_USER_FAIL = "selfhost/GET_WR_EVENTS_FOR_USER_FAIL",
  GET_WR_EVENTS_FOR_USER_SUCCESS = "selfhost/GET_WR_EVENTS_FOR_USER_SUCCESS",
  GET_ROOMS_REVIEWS_FOR_USER_REQUEST = "selfhost/GET_ROOMS_REVIEWS_FOR_USER_REQUEST",
  GET_ROOMS_REVIEWS_FOR_USER_SUCCESS = "selfhost/GET_ROOMS_REVIEWS_FOR_USER_SUCCESS",
}

export interface SelfhostTourVideo {
  id: number;
  defaultRoomId: string;
  pcUrl: string;
  s3Url: string;
  size: number;
  streamUrl?: string;
  name?: string;
  resolution?: string;
  thumbnailUrl?: string;
  difficulty?: string | null;
  MainCategories?: SelfhostCategories[];
  SubCategories?: SelfhostCategories[];
  VideoCategoryFreeDescriptions?: {
    subCategoryId: number;
    freeDescription: string;
  }[];
  GaussianSplatting?: {
    id: number;
    videoid: number;
    scaleX: number;
    scaleY: number;
    scaleZ: number;
  };
  categoryTags?: {
    id: string;
    name: string;
    nameJp: string;
    nameZhcn: string;
    nameZhtw: string;
    nameVi: string;
    nameHi: string;
  }[];
}

export interface SelfhostCategories {
  id: number;
  name: string;
  nameJp: string;
  nameZhcn: string;
  nameZhtw: string;
  nameVi: string;
  nameHi: string;
  hasFreeDescription?: boolean;
}

export interface SelfhostTourVideoDetailInterface {
  videoId?: number;
  mainCategories: (number | string)[];
  subCategories: (number | string)[];
  freeDescriptions: {
    [key: string]: string;
  };
}

export interface SelfhostTourVideoDetailInterface {
  videoId?: number;
  mainCategories: (number | string)[];
  subCategories: (number | string)[];
}

export interface SelfhostTourInterface {
  id?: number;
  videoId?: number;
  countryId: string;
  location: string;
  description: string;
  descriptionJp?: string;
  descriptionZhcn?: string;
  descriptionZhtw?: string;
  descriptionVi?: string;
  descriptionHi?: string;
  title: string;
  titleJp: string;
  titleHi: string;
  titleVi: string;
  titleZhcn: string;
  titleZhtw: string;
  public: boolean;
  isFree?: boolean;
  mainCategories?: (number | string)[];
  subCategories?: (number | string)[];
  freeDescriptions?: {
    [key: string]: string;
  };
  photos?: string[];
  reviewsCount?: number;
  averagedEvaluations?: string | null;
  paymentType?: string;
  Video?: SelfhostTourVideo;
  Reviews?: SearchTourBookingInterface[];
  preSelectedSubCategoriesByMainCategory?: {
    [key: string]: CheckboxValueType[];
  };
  preSelectedSubCategoriesCountByMainCategory?: {
    [key: string]: number;
  };
  isPrivate?: boolean;
  updatedAt?: string;
  host?: User;
  userId?: number;
  isFavoriteGuide?: boolean;
  category?: RoomCategoryType;
  type?: ServiceType;
  RoomPrivacy?: {
    id: number;
    permission: string;
  };
  // itineraryId is needed for World onetime payment
  Itineraries?: ItinerariesInterface[];
  pageView?: number;
  isRecommended?: boolean;
}

export interface User {
  avatarUrl: string;
  hostStory: string;
  name: string;
}
export interface UserHost {
  userId: number;
  User: User;
}

export interface SelfhostTourHistoryInterfaceLegacy {
  id: number;
  userId: number;
  explorePlaceId: number;
  createdAt: Date;
  review: string;
  evaluation: number;
  ReviewPhotos?: ReviewPhotoInterface[];
  ExplorePlace: SelfhostTourInterface;
}

export interface SelfhostTourHistoryRoomInterface {
  photos: string[];
  id: number;
  location: string;
  description: string;
  descriptionJp: string | null;
  descriptionZhcn: string | null;
  descriptionZhtw: string | null;
  descriptionVi: string | null;
  descriptionHi: string | null;
  category: string;
  title: string;
  titleJp: string | null;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
  type: string;
  RoomPrivacy: {
    id: number;
    permission: string;
  };
}

// export interface a {
//     id: number;
//     roomId: number;
//     userId: number;
//     createdAt: Date;
//     updatedAt: Date;
//     Room: SelfhostTourHistoryRoomInterface
// }

// export interface SelfhostTourHistoryInterface {
//   totalItems: number;
//   items : a[]
// }
export interface SelfhostTourHistoryInterface {
  id: number;
  roomId: number;
  userId: number;
  createdAt: Date;
  updatedAt: Date;
  Room: SelfhostTourHistoryRoomInterface;
}

export interface SelfhostTourReviewRoomInterface {
  id: number;
  category: string;
  title: string;
  titleJp: string | null;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
}

export interface SelfhostTourReviewInterface {
  id: number;
  userId: number;
  roomId: number;
  evaluation: number;
  comment: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  ReviewPhotos: ReviewPhotoInterface[];
  Room: SelfhostTourReviewRoomInterface;
}

export interface ReviewPhotoInterface {
  id: number;
  userId: number;
  explorePlaceHistoryId: number;
  photo: string;
}

export interface TourReviewFormdataInterface {
  evaluation: number;
  review: string;
  photos?: any[];
}

export interface CountryTourInterface {
  countryId: keyof typeof countriesGeoInfo;
  tourCount: number;
}

export interface CreatedTourHistoryInterface {
  id: number;
  roomId: number;
  userId: number;
  updatedAt: string;
  createdAt: string;
}

export interface ReviewItemInterface {
  id: number;
  userId: number;
  roomId: number;
  evaluation: number;
  comment: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  ReviewPhotos?: ReviewPhotoInterface[];
  Room: {
    id: number;
    category: string;
    title: string;
    titleJp: string | null;
    titleZhcn: string | null;
    titleZhtw: string | null;
    titleVi: string | null;
    titleHi: string | null;
  };
}

export interface ItinerariesInterface {
  Bookings?: SearchTourBookingInterface[];
  id: number;
  status: string;
  ItineraryPrices: ItineraryPriceInterface[];
  reserved: boolean;
}

export interface ItineraryPriceInterface {
  maximum: number | null;
  minimum: number | null;
  amount: number;
  currency: string;
}
